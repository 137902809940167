<template>
    <div class="ds__page ds__page--background">
        <DsSection>
            <DsHeader>
                <h1 class="title ds__title">
                    Planning onderbouw
                </h1>
            </DsHeader>

            <AppScheduleGroupWrapper v-if="groupedScheduleRows.length">
                <AppScheduleGroup
                    v-for="(scheduleGroup, index) in groupedScheduleRows"
                    :key="`schedule-group-${index}`"
                    :columns-missing-schedule-rows="columnsMissingScheduleRows"
                    :group-id="groupId"
                    :group-schedule="groupSchedule"
                    :index="index"
                    :schedule-grades="scheduleGrades"
                    :schedule-group="scheduleGroup"
                />
            </AppScheduleGroupWrapper>
        </DsSection>
    </div>
</template>

<script>
import AppScheduleGroup from "@/components/AppScheduleGroup";
import AppScheduleGroupWrapper from "@/components/AppScheduleGroupWrapper";
import DsHeader from "@/designsystem/components/DsHeader";
import DsSection from "@/designsystem/components/DsSection";
export default {
    name: "DsHome",
    components: {
        AppScheduleGroup,
        AppScheduleGroupWrapper,
        DsHeader,
        DsSection
    },
    data() {
        return {
            groupedScheduleRows: [
                {
                    "1": [
                        {
                            id: "U2NoZWR1bGVSb3c6MTY4NDk=",
                            startTime: "09:00",
                            endTime: "10:00",
                            theme: "Thema",
                            notes: "Notitie!",
                            purpose: "Doel",
                            subject: {
                                id: "U3ViamVjdDoxNzY=",
                                name: "Links of rechtshandig",
                                parent: {
                                    id: "U3ViamVjdDoxNjY=",
                                    name: "Fijne motoriek",
                                    __typename: "Subject"
                                },
                                __typename: "Subject"
                            },
                            subjectDescription: "",
                            isBreak: false,
                            evaluation: "Dit is een evaluatie!",
                            __typename: "ScheduleRow",
                            column: 1,
                            grade: 2
                        },
                        {
                            id: "U2NoZWR1bGVSb3c6MTY4NTA=",
                            startTime: "10:00",
                            endTime: "11:00",
                            theme: "Thema",
                            notes: "sadda",
                            purpose: "asdsa",
                            subject: {
                                id: "U3ViamVjdDoxNzE=",
                                name: "Gooien / Vangen",
                                parent: {
                                    id: "U3ViamVjdDoxNjc=",
                                    name: "Grove motoriek",
                                    __typename: "Subject"
                                },
                                __typename: "Subject"
                            },
                            subjectDescription: "",
                            isBreak: false,
                            evaluation: "",
                            __typename: "ScheduleRow",
                            column: 1,
                            grade: 2
                        },
                        {
                            id: "U2NoZWR1bGVSb3c6MTY4NTE=",
                            startTime: "11:00",
                            endTime: "12:00",
                            theme: "",
                            notes: "",
                            purpose: "",
                            subject: null,
                            subjectDescription: "",
                            isBreak: true,
                            evaluation: "",
                            __typename: "ScheduleRow",
                            column: 1,
                            grade: 2
                        },
                        {
                            id: "U2NoZWR1bGVSb3c6MTY4NTI=",
                            startTime: "12:00",
                            endTime: "13:00",
                            theme: "sadsaa",
                            notes: "asdadsada",
                            purpose: "asdadaa",
                            subject: {
                                id: "U3ViamVjdDoxNjE=",
                                name: "Werkhouding",
                                parent: {
                                    id: "U3ViamVjdDoxNjA=",
                                    name: "Werkaanpak",
                                    __typename: "Subject"
                                },
                                __typename: "Subject"
                            },
                            subjectDescription: "",
                            isBreak: false,
                            evaluation: "",
                            __typename: "ScheduleRow",
                            column: 1,
                            grade: 2
                        }
                    ]
                }
            ],

            columnsMissingScheduleRows: [2],

            groupSchedule: {
                id: "R3JvdXBTY2hlZHVsZTo1",
                notes: "",
                schedules: [
                    {
                        id: "U2NoZWR1bGU6MTg1Nw==",
                        grade: {
                            id: "R3JhZGU6Mg==",
                            grade: 2,
                            __typename: "Grade"
                        },
                        scheduleRows: [
                            {
                                id: "U2NoZWR1bGVSb3c6MTY4NDk=",
                                startTime: "09:00",
                                endTime: "10:00",
                                theme: "Thema",
                                notes: "Notitie!",
                                purpose: "Doel",
                                subject: {
                                    id: "U3ViamVjdDoxNzY=",
                                    name: "Links of rechtshandig",
                                    parent: {
                                        id: "U3ViamVjdDoxNjY=",
                                        name: "Fijne motoriek",
                                        __typename: "Subject"
                                    },
                                    __typename: "Subject"
                                },
                                subjectDescription: "",
                                isBreak: false,
                                evaluation: "",
                                __typename: "ScheduleRow"
                            },
                            {
                                id: "U2NoZWR1bGVSb3c6MTY4NTA=",
                                startTime: "10:00",
                                endTime: "11:00",
                                theme: "Thema",
                                notes: "sadda",
                                purpose: "asdsa",
                                subject: {
                                    id: "U3ViamVjdDoxNzE=",
                                    name: "Gooien / Vangen",
                                    parent: {
                                        id: "U3ViamVjdDoxNjc=",
                                        name: "Grove motoriek",
                                        __typename: "Subject"
                                    },
                                    __typename: "Subject"
                                },
                                subjectDescription: "",
                                isBreak: false,
                                evaluation: "",
                                __typename: "ScheduleRow"
                            },
                            {
                                id: "U2NoZWR1bGVSb3c6MTY4NTE=",
                                startTime: "11:00",
                                endTime: "12:00",
                                theme: "",
                                notes: "",
                                purpose: "",
                                subject: null,
                                subjectDescription: "",
                                isBreak: true,
                                evaluation: "",
                                __typename: "ScheduleRow"
                            },
                            {
                                id: "U2NoZWR1bGVSb3c6MTY4NTI=",
                                startTime: "12:00",
                                endTime: "13:00",
                                theme: "sadsaa",
                                notes: "asdadsada",
                                purpose: "asdadaa",
                                subject: {
                                    id: "U3ViamVjdDoxNjE=",
                                    name: "Werkhouding",
                                    parent: {
                                        id: "U3ViamVjdDoxNjA=",
                                        name: "Werkaanpak",
                                        __typename: "Subject"
                                    },
                                    __typename: "Subject"
                                },
                                subjectDescription: "",
                                isBreak: false,
                                evaluation: "",
                                __typename: "ScheduleRow"
                            }
                        ],
                        __typename: "Schedule"
                    },
                    {
                        id: "U2NoZWR1bGU6MTg1OA==",
                        grade: {
                            id: "R3JhZGU6Mw==",
                            grade: 3,
                            __typename: "Grade"
                        },
                        scheduleRows: [],
                        __typename: "Schedule"
                    }
                ],
                __typename: "GroupSchedule"
            },

            scheduleGrades: [1],
            groupId: "R3JvdXA6NDQ="
        };
    }
};
</script>
